<template>
  <View title="Impressum">
    Sebastian Deisel <br />
    <br />
    Contact me: sebastian.deisel@gmail.com
  </View>
</template>

<script>
import View from '@/components/layout/View'

export default {
  components: {
    View
  }
}
</script>
